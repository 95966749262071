import { Button, FlexBox, TextField, theme } from '@rario/shared-components'
import React from 'react'
import Link from 'next/link'
import { PLATFORM_ORIGIN } from 'config'
import { ThemeEnum } from 'interfaces'
import { getCDNUrl } from 'shared-components/utils'

const HomeLandingFirstFold: React.FunctionComponent = () => {
  return (
    <FlexBox
      position={'relative'}
      flexDirection={'column'}
      zIndex={999}
      height={PLATFORM_ORIGIN === ThemeEnum.RARIO ? '739px' : '520px'}
      backgroundImage={`url(${getCDNUrl('/App/images/rario-landing/first-fold-bg-v7.png')})`}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      <FlexBox
        px={'10px'}
        left={0}
        right={0}
        top={PLATFORM_ORIGIN === ThemeEnum.RARIO ? 85 : 105}
        flexDirection={'column'}
        textAlign={'center'}
        alignItems={'center'}
        position={'absolute'}
      >
        <TextField
          fontFamily={theme.fonts.primary}
          fontWeight="800"
          fontSize="23px"
          lineHeight="28.06px"
          textAlign="center"
          mb="43px"
          color={theme.colors.cosmicGreen}
        >
          LIVE NOW!
        </TextField>
      </FlexBox>

      <FlexBox
        px={'27px'}
        flexDirection={'column'}
        textAlign={'center'}
        position={'absolute'}
        alignItems={'center'}
        bottom={64}
      >
        <TextField
          fontWeight="400"
          fontSize="14px"
          lineHeight="16.59px"
          textAlign="center"
          whiteSpace="pre-line"
          mb="20px"
        >
          The Rario Buyback is{' '}
          <TextField
            fontWeight="500"
            fontSize="14px"
            lineHeight="16.59px"
            textAlign="center"
            color={theme.colors.cosmicGreen}
          >
            now live
          </TextField>
          ! Log in to explore all the details.{'\n\n'}
          The marketplace closed on November 6th, and your cards have been automatically delisted.
          You can view all your holdings in your Vault and now opt to participate in the Buyback
          program.
        </TextField>

        <Link href={'/login'} passHref>
          <a id={'login-btn'}>
            <Button
              primaryEdges
              cornerIndentation="10px"
              name={'LOGIN'}
              mx={'12px'}
              width={'130px'}
              variant={'dynamic'}
              fontSize={'14px'}
            />
          </a>
        </Link>
      </FlexBox>
    </FlexBox>
  )
}

export default HomeLandingFirstFold
